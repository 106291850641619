import { closestCenter, DndContext } from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { ArrowDropDown, ArrowDropUp, SettingsOutlined } from '@mui/icons-material';
import { debounce, IconButton, TableCell, TableRow } from '@mui/material';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  type ColumnDef,
  type ExpandedState,
  type Row,
  type RowSelectionState,
  type SortingState,
  type TableOptions,
  type Updater
} from '@tanstack/react-table';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useVirtualizer, type VirtualItem } from '@tanstack/react-virtual';
import { useDynamicHeight } from '@linx-ui/shared/hooks/useDynamicHeight';
import { getColumnsWithDefaultConfigs, tableFilterFn } from '@linx-ui/shared/utils';
import { CoreApiProvider } from './context';
import {
  HeaderCellWrapper,
  InnerHeaderCellWrapper,
  ResizerStyled,
  TableContainerStyled,
  TableHeadStyled,
  TableRowStyled,
  TableStyled,
  TableToolbar,
  TableWrapper
} from './CustomDataGrid.styled';
import { CustomDataGridBody } from './CustomDataGridBody';
import { getDefaultColumn } from './defaultColumn';
import { DraggableTableHeader } from './DraggableTableHeader';
import { CustomDataGridToolbar as Toolbar } from './toolbar';
import { type DataGridProps } from './types';
import { useColumnOrder } from './useColumnOrder';

const _CustomDataGrid = ({
  name,
  columns,
  data,
  rowBuffer = 3,
  loading = false,
  onRowClick,
  onRowsScrollEnd,
  enableGlobalFilter = false,
  enableHideColumns = false,
  enableSingleRowSelection = false,
  enableMultiRowSelection = false,
  showSelectAll = true,
  onChangeRowSelection,
  selectedRowIds,
  rowIdentifier = 'id',
  disableRowSelectionFn,
  disableRowExpansionFn,
  disableRowFn,
  enableRowExpansion = false,
  rowExpansionKey = 'subRows',
  RowExpansionComponent,
  components = {
    ColumnSortedAscendingIcon: <ArrowDropUp />,
    ColumnSortedDescendingIcon: <ArrowDropDown />,
    ColumnUnsortedIcon: <ArrowDropUp />,
    HideColumnsIcon: <SettingsOutlined />,
    NoDataFound: 'No data found'
  },
  defaultHiddenColumns = {},
  testId = '',
  onSearch,
  onSort,
  searchPlaceholder,
  disableQuerySearch,
  enableSorting = true,
  noToolbar = false,
  headerBgColor
}: DataGridProps) => {
  const [noDataFound, setNoDataFound] = useState(false);
  const [showLoader, setShowLoader] = useState<boolean>(loading);
  const [sorting, setSorting] = useState<SortingState>([]);
  const prevScrollHeight = useRef(0);

  const selectedRows = useMemo(() => selectedRowIds || [], [selectedRowIds]);
  const tableData = useMemo(() => data || [], [data]);

  useEffect(() => {
    if (sorting.length) {
      onSort?.(sorting);
    }
  }, [sorting]);

  const [columnVisibility, setColumnVisibility] = useState(defaultHiddenColumns);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [tableToolbarRef, toolbarHeight] = useDynamicHeight();
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [rowSelection, setRowSelection] = useState({});
  const [prevSelected, setPrevSelected] = useState({});
  const useInteractionRef = useRef<boolean>(false);
  const selectable = useMemo(
    () => enableSingleRowSelection || enableMultiRowSelection,
    [enableSingleRowSelection, enableMultiRowSelection]
  );
  const isRowClickable = useMemo(() => !!onRowClick || selectable, [selectable, onRowClick]);
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const dataLookup = useMemo(() => {
    return tableData.reduce((lookUp: any, dataPoint: any, index) => {
      lookUp[dataPoint[rowIdentifier]] = { ...dataPoint, index };

      if (dataPoint.subRows) {
        dataPoint.subRows.reduce((lookUp: any, subRow: any, subRowIndex: number) => {
          lookUp[subRow[rowIdentifier]] = { ...subRow, index: `${index}.${subRowIndex}` };
          return lookUp;
        }, lookUp);
      }
      return lookUp;
    }, {});
  }, [tableData]);

  useEffect(() => {
    if (tableData.length >= selectedRows.length) {
      const selected = selectedRows.reduce((prevValue: any, currentValue: any) => {
        const indexValue = dataLookup[currentValue]?.[rowIdentifier];
        if (indexValue !== undefined) {
          prevValue[indexValue] = true;
        }
        return prevValue;
      }, {});
      setRowSelection(selected);
    }
  }, [dataLookup, selectedRows]);

  useEffect(() => {
    if (!loading) {
      setShowLoader(loading);
    } else {
      setShowLoader(loading);
    }
  }, [loading]);

  const onRowSelectionChange = (e: Updater<RowSelectionState>) => {
    useInteractionRef.current = true;
    setPrevSelected(rowSelection);
    setRowSelection(e);
  };

  const multiSelectable = useMemo(() => {
    if (enableMultiRowSelection) {
      return true;
    } else if (enableRowExpansion && enableSingleRowSelection) {
      return true;
    } else {
      return false;
    }
  }, [enableMultiRowSelection, enableRowExpansion, enableSingleRowSelection]);

  const hasRowExpansionComponent = useMemo(() => {
    return enableRowExpansion && !!RowExpansionComponent;
  }, [RowExpansionComponent, enableRowExpansion]);

  const actionColumn: ColumnDef<any> | undefined = useMemo(() => {
    return getDefaultColumn(selectable, enableMultiRowSelection, enableRowExpansion, showSelectAll);
  }, [selectable, enableMultiRowSelection, enableRowExpansion, showSelectAll]);

  const tableColumns = useMemo(() => {
    const modifiedCols = getColumnsWithDefaultConfigs(columns);
    if (actionColumn) {
      return [actionColumn, ...modifiedCols];
    } else {
      return [...modifiedCols];
    }
  }, [actionColumn, columns]);

  const tableConfig: TableOptions<any> = {
    data: tableData,
    columns: tableColumns,
    columnResizeMode: 'onChange',
    getFilteredRowModel: getFilteredRowModel(),
    enableGlobalFilter,
    manualSorting: !!onSort,
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    sortDescFirst: false,
    state: {
      sorting,
      globalFilter,
      rowSelection,
      expanded,
      columnVisibility
    },
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    getRowCanExpand: (row: Row<any>) => {
      if (enableRowExpansion) {
        if (row.subRows?.length) {
          return true;
        }
        if (hasRowExpansionComponent) {
          if (disableRowExpansionFn) {
            return disableRowExpansionFn(row.original);
          } else {
            return true;
          }
        }
      }
      return false;
    },
    getSortedRowModel: getSortedRowModel(),
    enableSorting,
    getRowId: (row) => row[rowIdentifier]
  };

  if (enableGlobalFilter) {
    tableConfig.onGlobalFilterChange = setGlobalFilter;
    tableConfig.globalFilterFn = tableFilterFn;
  }

  if (selectable) {
    tableConfig.enableRowSelection = (row) => {
      if (disableRowSelectionFn) {
        return !disableRowSelectionFn(row.original);
      } else {
        return selectable;
      }
    };

    tableConfig.enableMultiRowSelection = (row) => {
      if (disableRowSelectionFn) {
        return !disableRowSelectionFn(row.original);
      } else {
        return multiSelectable;
      }
    };

    tableConfig.enableSubRowSelection = (row) => {
      if (disableRowSelectionFn) {
        return !disableRowSelectionFn(row.original);
      } else {
        return multiSelectable || selectable;
      }
    };

    tableConfig.onRowSelectionChange = onRowSelectionChange;
  }

  if (enableRowExpansion) {
    tableConfig.getExpandedRowModel = getExpandedRowModel();

    if (rowExpansionKey) {
      tableConfig.getSubRows = (row: any) => row[rowExpansionKey];
    }
    if (!selectable) {
      tableConfig.enableSubRowSelection = false;
    }
    tableConfig.onExpandedChange = setExpanded;
  }

  const table = useReactTable(tableConfig);

  const { columnOrder, handleDragEnd, resetColumns, toggleColumnVisibility } = useColumnOrder(name, table);

  const { rows } = table.getRowModel();

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 45,
    overscan: 20
  });

  const virtualRows = virtualizer.getVirtualItems();

  const isResizing = (isResizing: boolean): string => {
    return isResizing ? 'isResizing' : '';
  };

  useEffect(() => {
    if (!rows.length) {
      setNoDataFound(true);
    } else {
      setNoDataFound(false);
    }
  }, [rows]);

  const onRowClicked = (row: any, event: React.MouseEvent<HTMLElement>) => {
    onRowClick ? onRowClick?.(row, event) : selectable && table.getRow(row[rowIdentifier]).toggleSelected();
  };

  const onScrollEnd = useCallback(
    debounce((containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight: currentScrollHeight } = containerRefElement;
        if (currentScrollHeight < prevScrollHeight.current) {
          containerRefElement.scrollTop = 0;
          prevScrollHeight.current = currentScrollHeight;
        }

        const { scrollTop, clientHeight } = containerRefElement;
        if (scrollTop && currentScrollHeight - scrollTop - clientHeight < 1 && !loading) {
          prevScrollHeight.current = currentScrollHeight;
          onRowsScrollEnd?.();
        }
      }
    }, 500),
    [onRowsScrollEnd, loading]
  );
  const getSortingIcons = (type: string, canSort: boolean) => {
    if (canSort) {
      if (type === 'asc') {
        return (
          <IconButton aria-label="ascending" size="small" data-testid="sort-icon-ascending">
            {components.ColumnSortedAscendingIcon ?? <ArrowDropUp />}
          </IconButton>
        );
      } else if (type === 'desc') {
        return (
          <IconButton size="small" aria-label="descending" data-testid="sort-icon-descending">
            {components.ColumnSortedDescendingIcon ?? <ArrowDropDown />}
          </IconButton>
        );
      } else {
        return (
          <IconButton size="small" data-testid="sort-icon-unsorted" aria-label="unsorted" className="unsorted">
            {components.ColumnUnsortedIcon ?? <ArrowDropUp />}
          </IconButton>
        );
      }
    } else {
      return null;
    }
  };

  const getExpandedRowComponent = useCallback(
    (row: Row<any>, virtualRow: VirtualItem) => {
      if (enableRowExpansion && RowExpansionComponent && row.getIsExpanded()) {
        return (
          <TableRowStyled
            disabled={!!disableRowFn?.(row.original)}
            clickable={false}
            key={'expanded-row' + row.id}
            ref={virtualizer.measure}
            data-index={virtualRow.index}
          >
            <TableCell colSpan={row.getVisibleCells().length} key={'expanded-cell' + row.id}>
              <RowExpansionComponent row={row.original} />
            </TableCell>
          </TableRowStyled>
        );
      }
      return null;
    },
    [RowExpansionComponent, enableRowExpansion, disableRowFn]
  );

  const onSearchChange = (value: string) => {
    if (onSearch) {
      onSearch(value);
    } else {
      setGlobalFilter(value);
    }
  };

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    if (useInteractionRef.current) {
      useInteractionRef.current = false;
      const { flatRows, rows } = table.getSelectedRowModel();
      const prev = Object.keys(prevSelected);

      let filetered = flatRows;
      if (
        enableSingleRowSelection &&
        !(
          flatRows.length < prev.length ||
          (rows.length === 1 && !prev.includes(rows[0].id) && rows[0].subRows.some((row) => prev.includes(row.id)))
        )
      ) {
        filetered = filetered.filter((row) => !prev.includes(row.id));
      }

      const requiredRows = enableSingleRowSelection
        ? filetered.length === 1
          ? [filetered[0].original]
          : [filetered.find((row) => !row.parentId)?.original].filter(Boolean)
        : filetered.map((row) => row?.original);

      const requiredRowIds = requiredRows.map((row) => row?.[rowIdentifier]);

      onChangeRowSelection?.(requiredRowIds, requiredRows);
      const rowSelection = filetered.reduce((acc: any, curr) => {
        acc[curr.id] = true;
        return acc;
      }, {});

      setRowSelection(rowSelection);
    }
  }, [onChangeRowSelection, rowSelection, useInteractionRef.current]);

  const context = {
    publicApi: {
      onSearchChange,
      getAllColumns: table.getAllLeafColumns
    }
  };

  return (
    <CoreApiProvider value={context}>
      <TableWrapper data-testid={`${testId}-table-wrapper`}>
        {!noToolbar && (
          <TableToolbar ref={tableToolbarRef}>
            <Toolbar
              testId={`${testId}-toolbar`}
              components={{ HideColumnsIcon: components.HideColumnsIcon }}
              enableHideColumns={enableHideColumns}
              enableSearch={enableGlobalFilter}
              toolbarActions={components.ToolbarComponents}
              searchPlaceholder={searchPlaceholder}
              resetColumns={resetColumns}
              toggleColumnVisibility={toggleColumnVisibility}
              disableQuerySearch={disableQuerySearch}
            />
          </TableToolbar>
        )}

        <DndContext
          key="dnd-context"
          collisionDetection={closestCenter}
          modifiers={[restrictToHorizontalAxis]}
          onDragEnd={handleDragEnd}
          autoScroll={false}
        >
          <TableContainerStyled
            toolbarHeight={toolbarHeight}
            data-testid="table-container"
            ref={tableContainerRef}
            aria-label="Virtual list"
            onScroll={(e) => onScrollEnd(e.target as HTMLDivElement)}
          >
            <TableStyled width={table.getCenterTotalSize()} headerBgColor={headerBgColor}>
              <TableHeadStyled headerBgColor={headerBgColor}>
                {!!columnOrder.length && (
                  <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header, headerIndex) => (
                          <DraggableTableHeader
                            key={header.id}
                            headerBgColor={headerBgColor}
                            header={header}
                            resizer={
                              (!headerGroup.headers[headerIndex + 1]?.isPlaceholder || !header.isPlaceholder) && (
                                <ResizerStyled
                                  data-testid="column-resizer"
                                  isResizable={header.column.getCanResize()}
                                  {...{
                                    onMouseDown: header.getResizeHandler(),
                                    onTouchStart: header.getResizeHandler(),
                                    className: `columnSeperator ${isResizing(header.column.getIsResizing())}`
                                  }}
                                />
                              )
                            }
                          >
                            <HeaderCellWrapper
                              width={header.getSize()}
                              clickable={header.column.getCanSort()}
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              <InnerHeaderCellWrapper>
                                {!header.isPlaceholder &&
                                  flexRender(header.column.columnDef.header, header.getContext())}
                                {!header.isPlaceholder &&
                                  getSortingIcons(header.column.getIsSorted() as string, header.column.getCanSort())}
                              </InnerHeaderCellWrapper>
                            </HeaderCellWrapper>
                          </DraggableTableHeader>
                        ))}
                      </TableRow>
                    ))}
                  </SortableContext>
                )}
              </TableHeadStyled>
              <CustomDataGridBody
                isRowClickable={isRowClickable}
                disableRowFn={disableRowFn}
                virtualRows={virtualRows}
                virtualizer={virtualizer}
                rows={rows}
                loading={showLoader}
                useLoadingState={!onRowsScrollEnd}
                onRowClicked={onRowClicked}
                getExpandedRowComponent={getExpandedRowComponent}
                noDataFound={noDataFound}
                table={table}
                NoDataFound={components.NoDataFound}
                columnOrder={columnOrder}
              />
            </TableStyled>
          </TableContainerStyled>
        </DndContext>
      </TableWrapper>
    </CoreApiProvider>
  );
};

export const CustomDataGrid = memo(_CustomDataGrid) as typeof _CustomDataGrid;
