import { alpha, styled, Table, TableCell } from '@mui/material';
import { theme as themeToUse } from '@linx-ui/shared/theming';

const TableStyled = styled(Table)(() => ({
  borderCollapse: 'separate',
  borderSpacing: '4px 4px',

  td: {
    padding: '4px 16px 4px 16px',
    borderBottom: 'none'
  },
  th: {
    padding: '4px 16px 4px 16px',
    borderBottom: 'none'
  }
}));

const TableStyledPrint = styled(Table)(() => ({
  borderCollapse: 'separate',
  border: '1px solid',
  borderColor: themeToUse.palette.divider,
  borderRadius: '6px',
  td: {
    borderLeft: '1px solid',
    borderTop: '1px solid',
    borderBottom: 'none',
    borderColor: themeToUse.palette.divider,
    padding: '4px 16px 4px 16px',
    '&:nth-of-type(1)': {
      borderLeft: 'none'
    }
  },
  th: {
    borderLeft: '1px solid',
    borderTop: 'none',
    borderBottom: 'none',
    fontWeight: 700,
    borderColor: themeToUse.palette.divider,
    padding: '4px 16px 4px 16px',
    '&:nth-of-type(1)': {
      borderLeft: 'none'
    }
  }
}));

const TableCellDefault = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
  background: alpha(theme.palette.divider, 0.25)
}));

const TableCellError = styled(TableCell)(({ theme }) => ({
  fontWeight: 700,
  background: alpha(theme.palette.error.light, 0.04)
}));

const TableCellSecondary = styled(TableCell)(({ theme }) => ({
  fontWeight: 700,
  background: alpha(theme.palette.secondary.light, 0.08),
  color: theme.palette.secondary.dark
}));

const TableCellSuccess = styled(TableCell)(({ theme, color = theme.palette.secondary.dark }) => ({
  fontWeight: 700,
  background: alpha(theme.palette.success.light, 0.12),
  color
}));

export { TableStyled, TableCellSecondary, TableCellSuccess, TableCellError, TableCellDefault, TableStyledPrint };
