import { pharmacyIdKey } from '@linx-ui/shared/constants';

const getFromSessionStorage = (key: string) => {
  const item = window.sessionStorage.getItem(key);
  return item ? JSON.parse(item) : item;
};

const saveToSessionStorage = (key: string, value: any) => window.sessionStorage.setItem(key, JSON.stringify(value));

const removeFromSessionStorage = (key: string) => window.sessionStorage.removeItem(key);

const getFromLocalStorage = (key: string) => {
  const item = window.localStorage.getItem(key);
  return item ? JSON.parse(item) : item;
};

const clearSessionStorage = () => window.sessionStorage.clear();

const clearLocalStorage = () => window.localStorage.clear();

const saveToLocalStorage = (key: string, value: any) => window.localStorage.setItem(key, JSON.stringify(value));

const removeFromLocalStorage = (key: string) => window.localStorage.removeItem(key);

const savePharmacyId = (pharmacyId: string | undefined) => saveToLocalStorage(pharmacyIdKey, pharmacyId);

const getPharmacyId = () => getFromLocalStorage(pharmacyIdKey);

export {
  clearSessionStorage,
  clearLocalStorage,
  getFromLocalStorage,
  getFromSessionStorage,
  getPharmacyId,
  removeFromLocalStorage,
  removeFromSessionStorage,
  savePharmacyId,
  saveToLocalStorage,
  saveToSessionStorage
};
