import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme
} from '@mui/material';
import { Controller } from 'react-hook-form';
import type { RadioGroupWrapperProps } from './types';

const RadioGroupWrapper = (props: RadioGroupWrapperProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.value}
      render={({ field }) => (
        <FormControl
          fullWidth
          disabled={props.disabled}
          required={!!props.rules?.required}
          error={props.error?.hasError}
        >
          <FormLabel required={!!props.rules?.required} id={`${props.name}-radio-group-label`}>
            <Typography color={theme.palette.secondary.dark}>{props.label}</Typography>
          </FormLabel>
          <RadioGroup
            sx={props.style}
            {...field}
            onFocus={props.onFocus}
            row={props.row !== false}
            aria-labelledby={`${props.name}-radio-group-label`}
            name={`${props.name}-row-radio-buttons-group`}
            data-testid={props.dataTestId}
            onChange={(event) => {
              field.onChange(event.target.value);
              props.onChange?.(event.target.value);
            }}
          >
            {props.options.map((option: any, index: number) => (
              <FormControlLabel
                sx={{ width: props.fullWidth ? '100%' : 'auto' }}
                key={`${props.name}-radio-option-${index}`}
                value={option.value}
                control={<Radio />}
                disabled={option.disabled}
                label={option.label}
              />
            ))}
          </RadioGroup>
          {props.error?.message && <FormHelperText error>{props.error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export { RadioGroupWrapper };
