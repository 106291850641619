import { type Query } from '@tanstack/react-query';

const queriesToBeSaved = [
  /\/api\/pharmacies\/[a-z0-9-]+$/,
  /\/contracts\/(.+)\/pharmacies/,
  /\/api\/deliveries\/configurations$/,
  /\/api\/deliveries\/partners$/,
  /\/api\/pharmacies\/[a-z0-9-]+\/configurations$/
];

export const shouldDehydrateQuery = (query: Query) => {
  if (query.queryKey[0] && typeof query.queryKey[0] === 'string') {
    const [url, , params] = query.queryKey as [string, Record<string, unknown>, Record<string, unknown>];

    return (
      !!params || // Save the list data
      query.queryKey.length === 1 || // Save the key which is used to save the state
      queriesToBeSaved.find((rgx) => !!url.match(rgx))
    );
  } else {
    return false;
  }
};
