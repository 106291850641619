import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, useQueryErrorResetBoundary } from '@tanstack/react-query';
import { useEffect } from 'react';
import { axios } from '@linx-ui/shared/utils/axios';
import { getHeadersFromKeys } from '../../../utils';
import type { ApiErrorResponse } from '../useQueryHelpers/types';
import { useQueryHelpers } from '../useQueryHelpers/useQueryHelpers';
import type { UseReactQueryParams } from './types';

export const useReactQuery = <Data, Error = ApiErrorResponse, InterceptedData = Data>(
  config: UseReactQueryParams<Data, Error, InterceptedData>
) => {
  const {
    url,
    params,
    successMsg,
    errorMsg,
    severity,
    onError,
    onSuccess,
    showSuccessToast = true,
    showErrorToast = true,
    headerKeys = [],
    shouldRedirectToErrorPage = false,
    initialData,
    headers: defaultHeaders = { 'Content-Type': 'application/json' },
    responseType,
    ...restParams
  } = config;
  const auth = useAuth0();
  const headers = getHeadersFromKeys(headerKeys);
  const { reset } = useQueryErrorResetBoundary();
  const { raiseSuccessToast, raiseErrorToast, navigateToErrorPage } = useQueryHelpers({
    successMsg,
    errorMsg,
    severity,
    showErrorToast,
    showSuccessToast
  });

  const hasAllRequiredHeaders = headerKeys.every((value) => Object.keys(headers).includes(value));

  const queryResult = useQuery<Data, Error, InterceptedData>({
    ...{ ...restParams, enabled: restParams.enabled && hasAllRequiredHeaders && auth.isAuthenticated },
    initialData: initialData as Data,
    queryKey: [url, headers, params],
    queryFn: async () => {
      const response = await axios({
        url,
        method: 'GET',
        params,
        responseType,
        headers: { ...headers, ...defaultHeaders, Authorization: `Bearer ${await auth.getAccessTokenSilently()}` }
      });

      if ((response as unknown as ApiErrorResponse).error) {
        return await Promise.reject(response);
      }
      return response as Data;
    }
  });

  const { isSuccess, isError, error, data } = queryResult;

  useEffect(() => {
    if (isSuccess) {
      raiseSuccessToast(data);
      onSuccess?.(data);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError) {
      raiseErrorToast(error);
      onError?.(error);
      shouldRedirectToErrorPage && navigateToErrorPage(error);
      reset();
    }
  }, [isError, error]);

  return {
    ...queryResult,
    isLoading: initialData
      ? queryResult.isFetching && JSON.stringify(queryResult.data) === JSON.stringify(initialData)
      : queryResult.isLoading
  };
};
