import { getFromLocalStorage } from '@linx-ui/shared/utils/browserStorageUtils';

export const getHeadersFromKeys = (headerKeys: string[]) => {
  const headers: Record<string, string> = {};

  const getContextHeaders = () => {
    const contextHeaders: Record<string, string> = {};

    for (let i = 0; i < headerKeys.length; i++) {
      const headerKey = headerKeys[i];
      const headerValue = getFromLocalStorage(headerKey);
      if (headerValue) {
        contextHeaders[headerKey] = headerValue;
      }
    }
    return contextHeaders;
  };

  const contextHeaders = getContextHeaders();
  return { ...headers, ...contextHeaders };
};
