import { useQueryClient } from '@tanstack/react-query';
import get from 'lodash/get';
import { type PaginatedResult } from '@linx-ui/shared/types';
import { TIME_STRING } from '@linx-ui/shared/utils/validations';
import { getHeadersFromKeys } from '../../../utils';
import type { ApiErrorResponse } from '../useQueryHelpers/types';
import { useReactMutation } from '../useReactMutation';
import type { UsePostMutationParams } from './types';

export const usePostMutation = <
  Data extends { id?: string },
  PayloadData = any,
  InterceptedData extends { id?: string } = Data,
  Error = ApiErrorResponse
>({
  sortKey = 'recentHistory.createdTime',
  sortOrder = 'desc',
  listUrl,
  listQueryParams = {},
  useOptimisticUpdate = true,
  method = 'POST',
  ...config
}: UsePostMutationParams<Data, PayloadData, InterceptedData, Error>) => {
  const queryClient = useQueryClient();
  const headers = getHeadersFromKeys(config.headerKeys ?? []);

  const listQueryKey = [listUrl ?? config.url, headers, { limit: -1, offset: 0, ...listQueryParams }];

  return useReactMutation<Data, PayloadData, InterceptedData, Error>({
    ...config,
    method,
    onSuccess: async (newData, variable, context) => {
      const detailsQueryKey = [`${config.url}/${newData?.id}`, headers, config.params];
      const previousListData = queryClient.getQueryData(listQueryKey);

      await queryClient.cancelQueries({ queryKey: detailsQueryKey });
      await queryClient.cancelQueries({ queryKey: listQueryKey });

      useOptimisticUpdate && queryClient.setQueryData(detailsQueryKey, newData);

      if (previousListData && useOptimisticUpdate) {
        queryClient.setQueryData(listQueryKey, (previous: PaginatedResult<{ id?: string }>) => {
          let result: PaginatedResult<{ id?: string }>;

          const previousItems = previous.items || [];
          const index = previousItems.findIndex((item) => item.id === newData?.id);

          if (index !== -1) {
            result = { ...previous, items: previousItems.toSpliced(index, 1, newData) };
          } else {
            result = {
              items: [newData, ...previousItems],
              metaData: {
                ...previous.metaData,
                count: previous.metaData.count + 1
              }
            };
          }

          result.items = result.items?.sort((a, b) => {
            const isDateField = TIME_STRING.test(get(a, sortKey));
            const isStringField = typeof get(a, sortKey) === 'string';
            const isBooleanField = typeof get(a, sortKey) === 'boolean';
            const aValue = isDateField ? new Date(get(a, sortKey)) : get(a, sortKey);
            const bValue = isDateField ? new Date(get(b, sortKey)) : get(b, sortKey);

            if (isStringField && !isDateField) {
              return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }
            if (isBooleanField) {
              return sortOrder === 'asc' ? (aValue ? -1 : 1) : aValue ? 1 : -1;
            }
            return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
          });

          return result;
        });
      }
      config?.onSuccess?.(newData, variable, context);
    },
    onSettled: async (data, error, variables, context) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [config.url, headers] }),
        queryClient.invalidateQueries({ queryKey: [`${config.url}/${data?.id}`, headers, config.params] })
      ]);
      config?.onSettled?.(data, error, variables, context);
    }
  });
};
