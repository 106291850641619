import { getToken } from 'firebase/messaging';
import { useCallback, useEffect, useState } from 'react';
import { urls } from '@linx-ui/shared/constants';
import { useGlobalContext } from '@linx-ui/shared/context';
import { useReactMutation } from '@linx-ui/shared/hooks/api/useReactMutation';
import { isLinxAdmin } from '@linx-ui/shared/utils';
import { messaging } from './app';

export const useFirebaseRegisterClient = (appName: string) => {
  const { auth } = useGlobalContext();
  const linxAdmin = isLinxAdmin(auth);
  const [shouldRegister, setShouldRegister] = useState(true);

  const { mutate } = useReactMutation({
    url: urls.registerFirebaseClient,
    method: 'POST',
    onSuccess: () => setShouldRegister(false)
  });
  const fbServiceWorkerUrl =
    process.env.NODE_ENV === 'production' ? `/${appName}/firebase-messaging-sw.js` : `/firebase-messaging-sw.js`;

  const registerClient = useCallback(async () => {
    if (shouldRegister && messaging && !linxAdmin && 'serviceWorker' in navigator && 'Notification' in window) {
      try {
        const permission = await Notification.requestPermission();
        console.log(`Notification permission ${permission}.`);
        const registration = await navigator.serviceWorker.register(fbServiceWorkerUrl, {
          scope: `/${appName}/firebase-cloud-messaging-push-scope`
        });
        const token = await getToken(messaging!, {
          vapidKey: process.env.FIREBASE_VAPID_KEY,
          serviceWorkerRegistration: registration
        });
        mutate({ token });
        console.log('Successfully registered firebase service worker and able to fetch token also.');
      } catch (error) {
        console.log('Unable to fetch the firebase token: ', error);
      }
    }
  }, [appName, fbServiceWorkerUrl, linxAdmin, mutate, shouldRegister]);

  useEffect(() => {
    document.addEventListener('click', registerClient);
    return () => document.removeEventListener('click', registerClient);
  }, [registerClient]);
};
