import { Typography } from '@mui/material';
import { useState } from 'react';
import type { FileRejection } from 'react-dropzone/.';
import { AlertMessage } from '@linx-ui/shared/components/alertMessage';
import { FileInput, FileRejectionErrorCode } from '@linx-ui/shared/components/fileInput';
import { StyledSkeleton } from '@linx-ui/shared/components/styles';
import { Uploader } from '@linx-ui/shared/components/uploader';
import { TEN_MB } from '@linx-ui/shared/constants';
import { useGlobalContext } from '@linx-ui/shared/context';
import { useFeaturePermissions } from '@linx-ui/shared/core';
import { useReactQueryList } from '@linx-ui/shared/hooks/api/useReactQueryList';
import { FilesCnt, FilesCntInner, FilesUploadInfoCnt, MainCnt } from './Documents.styled';
import { ListItem } from './listItem';
import { type DocumentProps, type FileItem } from './types';

const Documents = <Result,>({
  entityId,
  headerKeys,
  url,
  showUploadedFiles,
  fileInputProps,
  onUpload,
  folderName = 'files',
  featureName
}: DocumentProps<Result>) => {
  const { data, refetch, isLoading } = useReactQueryList<FileItem>({
    url: [url, entityId, folderName].filter((value) => !!value).join('/'),
    headerKeys,
    enabled: !!showUploadedFiles
  });

  const hasPermissionToUploadDocs = useFeaturePermissions()(featureName)('update');

  const onlyUnique = (value: string, index: number, array: string[]) => {
    return array.indexOf(value) === index;
  };

  const getRejectMessages = (rejectedFiles: FileRejection[]) => {
    return rejectedFiles
      .map((file) => {
        const error = file.errors[0].code;
        if (error === FileRejectionErrorCode.FileTooLarge) {
          return `[${file.file.name}] File size should not exceed 10 MB.`;
        } else if (error === FileRejectionErrorCode.TooManyFiles) {
          return 'Only one file at a time is allowed.';
        } else {
          return `[${file.file.name}] ${fileInputProps.fileTypeErrorMsg}`;
        }
      })
      .filter(onlyUnique);
  };

  const { setSnackBarState } = useGlobalContext();
  const [allSelectedFiles, setAllSelectedFiles] = useState<File[]>([]);

  const onFilesChosen = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    rejectedFiles.length &&
      setSnackBarState({
        open: true,
        severity: 'error',
        messages: getRejectMessages(rejectedFiles)
      });
    setAllSelectedFiles((files) => {
      return files?.concat(acceptedFiles);
    });
  };

  const onCancel = (index: number) => {
    setAllSelectedFiles(
      allSelectedFiles.filter((el: File, ind: number) => {
        return ind !== index;
      })
    );
  };

  const onDeleted = () => {
    if (showUploadedFiles) {
      void refetch();
    }
  };
  const onUploaded = (result: Result) => {
    onUpload?.(result);
    if (showUploadedFiles) {
      void refetch();
    }
  };

  return (
    <MainCnt>
      {hasPermissionToUploadDocs && (
        <FileInput
          maxSize={TEN_MB}
          accept={fileInputProps.accept}
          multiple={false}
          title={fileInputProps.title}
          subTitle={fileInputProps.subTitle}
          onFilesChosen={onFilesChosen}
          disabled={fileInputProps?.disabled}
        />
      )}

      <FilesUploadInfoCnt>
        {hasPermissionToUploadDocs && (
          <FilesCnt showUploadedFiles={showUploadedFiles}>
            <Typography variant="body2" color="secondary">
              Uploading Files
            </Typography>
            {allSelectedFiles?.length ? (
              <FilesCntInner>
                {allSelectedFiles?.map((file, index) => {
                  return (
                    <Uploader<Result>
                      key={index}
                      file={file}
                      entityId={entityId}
                      folderName={folderName}
                      headerKeys={headerKeys}
                      url={url}
                      index={index}
                      onCancel={onCancel}
                      onSuccess={onUploaded}
                    />
                  );
                })}
              </FilesCntInner>
            ) : (
              <AlertMessage alertText="No documents under upload" />
            )}
          </FilesCnt>
        )}

        {showUploadedFiles && (
          <FilesCnt showUploadedFiles={showUploadedFiles}>
            <Typography variant="body2" color="secondary">
              Uploaded Files
            </Typography>
            <FilesCntInner>
              {!isLoading ? (
                data.items.length ? (
                  data.items.map((file: any) => (
                    <ListItem
                      key={file.id}
                      {...file}
                      url={url}
                      folderName={folderName}
                      hasThreats={!!file.hasThreats}
                      headerKeys={headerKeys}
                      onDeleteSuccess={onDeleted}
                    />
                  ))
                ) : (
                  <AlertMessage alertText="No documents uploaded" />
                )
              ) : (
                <>
                  <StyledSkeleton />
                  <StyledSkeleton />
                </>
              )}
            </FilesCntInner>
          </FilesCnt>
        )}
      </FilesUploadInfoCnt>
    </MainCnt>
  );
};

export { Documents };
