import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CancelAction, SubmitAction } from '@linx-ui/shared/components/Actions';
import { DrawerContainer } from '@linx-ui/shared/components/drawerContainer';
import { DRAWER_WIDTH, SharedShortcutIds } from '@linx-ui/shared/constants';
import { useKeyboardTrigger } from '@linx-ui/shared/context/keyboardContext';
import { usePostMutation } from '@linx-ui/shared/hooks/api';
import { hasChanged } from '../utils';
import { type CreateWrapperProps } from './types';

export function CreateWrapper<Data extends { id?: string }, PayloadData = Data, MetaData = unknown>({
  data,
  metaData,
  successCallback,
  cancelActionCallback,
  url,
  headers,
  CreateComponent,
  drawerWidth,
  headerKeys,
  scrollIndex,
  headerComponents,
  implicitFormProperties,
  payloadProcessor,
  open = false,
  triggerBtn,
  formDataCallback,
  titleTxt,
  submitButtonTxt,
  cancelButtonTxt,
  successMsg,
  successTitle,
  listUrl,
  listQueryParams,
  defaultSortKey,
  defaultSortOrder,
  overrideSave,
  customShortcutId,
  disabled,
  useOptimisticUpdate,
  customOnFormSubmit,
  erxToggleDefaultValue,
  noteInputRef,
  httpMethod
}: CreateWrapperProps<Data, PayloadData, MetaData>) {
  const onSuccess = (result: Data) => {
    setFormData(undefined);
    setIsDrawerOpen(false);
    successCallback?.(result);
  };

  const { isPending, mutate } = usePostMutation<Data, Data | PayloadData>({
    url,
    successMsg,
    successTitle,
    onSuccess,
    headerKeys,
    headers,
    listUrl,
    listQueryParams,
    sortKey: defaultSortKey,
    sortOrder: defaultSortOrder,
    useOptimisticUpdate,
    method: httpMethod
  });

  const [formData, setFormData] = useState<Data>();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(!overrideSave);
  const [isDrawerOpen, setIsDrawerOpen] = useState(open);

  const openDrawer = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);

  const drawerShortcut =
    customShortcutId ?? (!data ? SharedShortcutIds.CREATE_NEW_ENTITY : SharedShortcutIds.UPDATE_ENTITY);

  useKeyboardTrigger(drawerShortcut, openDrawer, disabled);

  const toggle = useCallback((): void => {
    setIsDrawerOpen((prev) => !prev);
  }, []);

  const removeProperty = (prop: string, { [prop]: exclProp, ...rest }) => rest;

  useEffect(() => {
    let cloneFormData = cloneDeep(formData ?? {});
    implicitFormProperties.forEach((prop) => {
      cloneFormData = removeProperty(prop, cloneFormData);
    });
    if (isFormComplete && Object.keys(data ?? {}).length) {
      setIsSaveButtonDisabled(!hasChanged(formData as object, data as object) && !overrideSave);
    } else if (!Object.keys(cloneFormData).length) {
      setIsSaveButtonDisabled(true);
    } else {
      setIsSaveButtonDisabled(!isFormComplete);
    }
    formDataCallback?.(formData!);
  }, [isFormComplete, JSON.stringify(formData)]);

  const onFormComplete = (data: Data): void => setFormData(data);

  const onFormSubmit = useCallback(() => {
    const data = payloadProcessor ? payloadProcessor(formData!) : formData!;
    mutate(data);
  }, [formData]);

  const cancelAction = useMemo(
    () => <CancelAction onClick={cancelActionCallback ?? toggle} btnText={cancelButtonTxt} />,
    [cancelActionCallback, toggle, cancelButtonTxt]
  );

  const submitAction = useMemo(
    () => (
      <SubmitAction
        onClick={customOnFormSubmit ? () => customOnFormSubmit?.(formData!, setFormData, onFormSubmit) : onFormSubmit}
        disabled={isSaveButtonDisabled}
        btnText={submitButtonTxt}
      />
    ),
    [onFormSubmit, formData, customOnFormSubmit, setFormData, isSaveButtonDisabled, submitButtonTxt]
  );

  const actionBtnsRight = useMemo(() => {
    return [cancelAction, submitAction];
  }, [cancelAction, submitAction]);

  return (
    <DrawerContainer
      triggerBtn={triggerBtn}
      openDrawer={isDrawerOpen}
      toggle={toggle}
      closeActionCallback={cancelActionCallback}
      title={titleTxt}
      headerComponents={headerComponents}
      actionBtnsRight={actionBtnsRight}
      loader={isPending}
      width={drawerWidth ?? `calc(100% - ${DRAWER_WIDTH}px)`}
    >
      <CreateComponent
        onFormComplete={onFormComplete}
        setIsFormComplete={setIsFormComplete}
        data={data}
        metaData={metaData}
        scrollIndex={scrollIndex}
        noteInputRef={noteInputRef}
        erxToggleDefaultValue={erxToggleDefaultValue}
      />
    </DrawerContainer>
  );
}
