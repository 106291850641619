import { createContext, useContext, type FC, type PropsWithChildren } from 'react';
import { useReactQuery } from '../hooks';
import { pharmacyIdKey, urls } from '../constants';

export type FeatureToggles = Record<FeatureToggleKeys, { enabled: boolean }>;

export type FeatureToggleKeys = 'claim-reconciliation'; // modify as flags are added eg. 'toggle-1' | 'toggle-2'

export const FeatureToggleContext = createContext<FeatureToggles | undefined>(undefined);

export const useFeatureToggle = (toggleKey: FeatureToggleKeys) => {
  const featureToggles = useContext(FeatureToggleContext);
  return featureToggles?.[toggleKey]?.enabled !== false;
};

export const useAllFeatureToggles = () => {
  const featureToggles = useContext(FeatureToggleContext);
  return featureToggles;
};

export const FeatureToggleProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data } = useReactQuery<FeatureToggles>({
    url: urls.featureToggle,
    headerKeys: [pharmacyIdKey]
  });

  return <FeatureToggleContext.Provider value={data}>{children}</FeatureToggleContext.Provider>;
};
