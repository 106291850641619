import moment, { type Moment } from 'moment-timezone';

const getDateTimeDisplayValue = (value: string) => value && moment(value).format('lll');

const getDisplayDateWithSlashFormatter = (value: string | undefined) => value && moment(value).format('MM/DD/YYYY');

const getDisplayDateTimeWithSlashFormatter = (value?: string) => value && moment(value).format('MM/DD/YYYY hh:mm A');

const getDisplayTime = (value?: string) => value && moment(value).format('hh:mm A');

const dashYYYYMMDDToSlashMMDDYYYY = (value: string) => value && moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY');

const getDisplayDateWithDashYYYYMMDD = (value?: string | Moment) => moment(value).format('YYYY-MM-DD');

function convertUtcTimeToLocal(utcTime: string) {
  const localTimeZone = moment.tz.guess();
  return moment.utc(utcTime, 'HH:mm').tz(localTimeZone).format('HH:mm');
}

const getDateDisplayValueLTLS = (value: string) => moment(value).format('LTS,l');

const daysUntilDue = (targetDate: string): number => {
  const currentDate: Moment = moment().startOf('day');
  const targetDateObj: Moment = moment(targetDate).startOf('day');
  return targetDateObj.diff(currentDate, 'days');
};

export {
  dashYYYYMMDDToSlashMMDDYYYY,
  daysUntilDue,
  getDateDisplayValueLTLS,
  getDateTimeDisplayValue,
  getDisplayDateTimeWithSlashFormatter,
  getDisplayDateWithSlashFormatter,
  getDisplayDateWithDashYYYYMMDD,
  getDisplayTime,
  convertUtcTimeToLocal
};
