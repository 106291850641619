import type { NavigationItem, NavigationOption } from '@linx-ui/shared/components';
import type { FeatureToggles } from '@linx-ui/shared/context/FeatureToggleContext';
import type { AllActionType, FeaturesNames, useFeaturePermissions } from '@linx-ui/shared/core';

const filterItems = (
  navigationItems: NavigationItem[],
  hasPermissionToView: (featureName: FeaturesNames) => (actionType: AllActionType) => boolean
): NavigationItem[] =>
  navigationItems
    .filter((item): boolean => {
      if (item.subItems.length > 0) {
        return filterItems(item.subItems, hasPermissionToView).length > 0;
      }
      return item.checkPermission ? hasPermissionToView(item.featureName)(item.actionType) : true;
    })
    .map((item) => ({ ...item, subItems: filterItems(item.subItems, hasPermissionToView) }));

const filterFeatureToggle = (navigationItems: NavigationItem[], featureToggles?: FeatureToggles): NavigationItem[] =>
  navigationItems
    .filter((item): boolean => {
      if (item.featureToggle && featureToggles?.[item.featureToggle]?.enabled === false) {
        return false;
      }
      if (item.subItems.length > 0) {
        return filterFeatureToggle(item.subItems, featureToggles).length > 0;
      }
      return true;
    })
    .map((item) => ({ ...item, subItems: filterFeatureToggle(item.subItems, featureToggles) }));

export const filterNavigationOption = (
  navigationOptions: NavigationOption[],
  hasPermissionToView: ReturnType<typeof useFeaturePermissions>,
  featureToggles?: FeatureToggles
) =>
  navigationOptions
    .filter(
      (option) =>
        filterItems(option.navigationItems, hasPermissionToView).length > 0 &&
        filterFeatureToggle(option.navigationItems, featureToggles)
    )
    .map((option) => {
      const navigationItems = filterItems(option.navigationItems, hasPermissionToView);
      const updatedItems = filterFeatureToggle(navigationItems, featureToggles);
      return {
        ...option,
        navigationItems: updatedItems
      };
    });
